// const React = require("react")
require('./static/tailwind-global.css')
require('./static/app-global.css')

// 移动端调试
// import VConsole from 'vconsole'
// const vConsoleObj = new VConsole();

/* Called when the Gatsby browser runtime first starts. */
exports.onClientEntry = () => {
  change()
  function change() {
    /* 这里的html字体大小利用了一个简单书序公式（十字相乘），当我们默认设置以屏幕320px位基准此时的字体大小为20px(320 20px),那么浏览器窗口大小改变的时候新的html的fontSize（clientWidth  新的值）就是clientWidth*20/320 */
    if (document.documentElement.clientWidth <= 768) {
      document.documentElement.style.fontSize = (document.documentElement.clientWidth * 10) / 375 + 'px'
    } else if (document.documentElement.clientWidth > 768 && document.documentElement.clientWidth < 1080) {
      document.documentElement.style.fontSize = (document.documentElement.clientWidth * 10) / 768 + 'px'
    } else {
      // document.documentElement.style.fontSize = 16 + 'px';
      document.documentElement.style.fontSize = (document.documentElement.clientWidth * 10) / 1440 + 'px'
    }
  }
  /* 监听窗口大小发生改变时 */
  window.addEventListener('resize', change, false)
}
